body {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  /* font-family: "Archivo Black", sans-serif; */
  background-color: #12101e;
}

/* Reset CSS */
* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background: #3d3d3d;
}
