@import '%PUBLIC_URL%/../src/Assets/Sass/_color.sass'

.loader
    height: 100vh
    width: 100%
    &__block
        z-index: 12
        position: absolute
        transform: translate(-50%,-50%)
        top: 50%
        left: 50%
        $size: 150px
        width: $size
        height: $size
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        &--cube
            position: relative
            height: 64px
            width: 64px
            margin: 5px
            overflow: hidden
            border-radius: 11px
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.45)

.blur
    width: 100%
    height: 100%
    background-color: $colorLoader
    filter: blur(10px)
    box-shadow: 0px 0px 18px 0px #4a1dfffa

#first
    animation: 1s load infinite

#second
    animation: 1s load infinite
    animation-delay: 250ms

#third
    animation: 1s load infinite
    animation-delay: 750ms

#fourth
    animation: 1s load infinite
    animation-delay: 500ms

@keyframes load
    0%
        opacity: 100%
    50%
        opacity: 0%
    100%
        opacity: 100%
