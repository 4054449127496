// General
$colorHeaderHealine: #e7e7e7
$colorTechBlock: #17171798
$colorBorderButton: #3d3d3d48
$colorText: #cecece

// Loader 
$colorLoader: #a524d8

// Footer
$colorFooterBorder: #0d4d1d75
